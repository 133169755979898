import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
  Text,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  AbsoluteCenter,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import React from "react";
import { OtherSourceData } from "src/types";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getOtherSources } from "src/api/sal-magic/query";
import { FREQUENCY_ROUTE, OTHER_SOURCES_ROUTE, WAVES_ROUTE } from "src/routes";
import SalMagicGate from "./components/SalMagicGate";
import SalMagicAudioPlayer from "./components/SalMagicPlayer/SalMagicAudioPlayer";
import { Link as ReactRouterLink } from "react-router-dom";

const OtherSources = () => {
  const {
    data: otherSourcesData,
    isLoading: isOtherSourcesLoading,
    error,
  } = useQuery<OtherSourceData, Error>(
    ["listOtherSources", localStorage.getItem("locale")],
    getOtherSources,
    {
      enabled: true,
      retry: 3,
      retryDelay: 1000,
      refetchOnWindowFocus: false,
    }
  );

  if (isOtherSourcesLoading) {
    return (
      <AbsoluteCenter>
        <Spinner aria-label="loading..." size="xl" />
      </AbsoluteCenter>
    );
  }

  if (error) {
    return (
      <Box my={10}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error loading data</AlertTitle>
          <AlertDescription>
            <FormattedMessage
              id="dataLoadError"
              defaultMessage="Unable to load the data. Please try again later."
            />
          </AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      </Box>
    );
  }

  return (
    <Box my={10}>
      <ChakraBreadcrumb color="gray.900" textTransform="capitalize" separator=" " mb={10}>
        <BreadcrumbItem w="100%">
          <>
            <BreadcrumbLink as={ReactRouterLink} to={FREQUENCY_ROUTE}>
              <FormattedMessage id="frequency" />
            </BreadcrumbLink>
            &nbsp;-&nbsp;
            <BreadcrumbLink as={ReactRouterLink} to={WAVES_ROUTE}>
              <FormattedMessage id="waves" />
            </BreadcrumbLink>
            &nbsp;-&nbsp;
            <BreadcrumbLink as={ReactRouterLink} to={OTHER_SOURCES_ROUTE}>
              <FormattedMessage id="Other Sources" />
            </BreadcrumbLink>
          </>
        </BreadcrumbItem>

        <BreadcrumbItem
          textStyle="h2"
          isCurrentPage
          w="100%"
          justifyContent="space-between"
          alignItems="start"
        >
          <Heading as="h2" textStyle={"h2"}>
            <FormattedMessage id="Other Sources" />
          </Heading>
          <SalMagicGate radius={105} />
        </BreadcrumbItem>
      </ChakraBreadcrumb>
      <VStack
        display={"flex"}
        wrap={"wrap"}
        margin={"auto"}
        width="100%"
        flexDir="row"
        justifyContent="center"
        alignItems="center"
      >
        <Accordion allowMultiple w="100%">
          {otherSourcesData
            ?.sort((a, b) => a.id - b.id)
            .map((category) => (
              <span id={`category-${category.id}`}>
                <AccordionItem
                  key={`item-${category.id}`}
                  id={`category-${category.id}`}
                >
                  <h2>
                    <AccordionButton key={`button-${category.id}`}>
                      <Text
                        flex="1"
                        textAlign="left"
                        fontWeight="bold"
                        fontSize={18}
                      >
                        {category.name}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={10}>
                    <VStack width="100%" spacing={6}>
                      {category.other_sources
                        .sort((a, b) => a.id - b.id)
                        .map((source, index) => (
                          <VStack
                            key={`${index}:vstack-other-source${source.id}`}
                            width="100%"
                          >
                            <SalMagicAudioPlayer
                              key={`${index}:other-source-${source.id}`}
                              entityName={"other_sources"}
                              trackUrl={source.url}
                              trackName={source.name}
                              trackCategory={category.name}
                              trackInfo={source.description}
                              trackId={source.id}
                              trackCover={source.imageUrl}
                              categoryId={category.id}
                            />
                            {index < category.other_sources.length - 1 && (
                              <hr
                                style={{
                                  margin: "36px",
                                  height: "2px",
                                  width: "80%",
                                }}
                              />
                            )}
                          </VStack>
                        ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </span>
            ))}
        </Accordion>
      </VStack>
    </Box>
  );
};

export default OtherSources;
